import type {
  MasonryEngineNodeRendererProps,
  MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { type PropsWithChildren } from 'react';
import { HtmlToReact } from '@leagueplatform/web-common';
import { UtilityText, Box } from '@leagueplatform/genesis-core';

type PrescriptionsRichTextNodeProperties = {
  text: string;
};

type PrescriptionsRichTextNode = MasonryEngineNode<
  'authorizationLearnMoreCard',
  PrescriptionsRichTextNodeProperties
>;

type PrescriptionsRichTextProps =
  MasonryEngineNodeRendererProps<PrescriptionsRichTextNode>;

const RichTextContainer = ({ children }: PropsWithChildren<{}>) => (
  <Box
    css={{
      marginBlockEnd: '$oneAndHalf',
    }}
  >
    <UtilityText css={{ typography: '$bodyOne' }}>{children}</UtilityText>
  </Box>
);

export const PrescriptionsRichText = ({ text }: PrescriptionsRichTextProps) => (
  <HtmlToReact
    htmlString={text}
    options={{
      span: {
        component: RichTextContainer,
      },
    }}
  />
);
