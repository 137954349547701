import {
  HeadingText,
  TextAction,
  StackLayout,
  StackItem,
} from '@leagueplatform/genesis-core';
import {
  type MasonryEngineNodeRendererProps,
  useMasonryEngineActionEmitter,
  type MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { type MasonryDeeplinkAction } from '@leagueplatform/masonry-renderers';
import { Link } from '@leagueplatform/routing';
import { RefillScoreStatusBadge } from 'components/refill-score/refill-score-status-badge.component';
import type { RefillScoreStatusColors } from 'common/types/types';
import { useElevatedCard } from 'hooks/use-elevated-card/use-elevated-card';

type RefillScoreCardNodeProperties = {
  heading: string;
  body: string;
  color: RefillScoreStatusColors;
  image: string;
  label: string;
};

type RefillScoreCardNodeActions = {
  onClick: MasonryDeeplinkAction;
};

type RefillScoreCardNode = MasonryEngineNode<
  'simpleBadgeCard',
  RefillScoreCardNodeProperties,
  RefillScoreCardNodeActions
>;

type RefillScoreCardProps = MasonryEngineNodeRendererProps<RefillScoreCardNode>;

export const RefillScoreCard = ({
  color,
  heading,
  image,
  label,
  onClick,
}: RefillScoreCardProps) => {
  const { primaryActionRef, ElevatedCard } = useElevatedCard();
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <ElevatedCard>
      <StackLayout
        orientation="horizontal"
        horizontalAlignment="spaceBetween"
        verticalAlignment="center"
      >
        <StackItem css={{ gap: '$threeQuarters' }}>
          <HeadingText
            level="3"
            size="sm"
            css={{
              typography: '$subtitleOne',
              marginBlockEnd: '$none',
            }}
          >
            <TextAction
              as={Link}
              to={onClick?.payload.url}
              ref={primaryActionRef}
              css={{
                display: 'block',
                width: 'fit-content',
              }}
              onClick={(event: React.MouseEvent) => {
                if (onClick) {
                  invokeAction({
                    event,
                    ...onClick,
                  });
                }
              }}
            >
              {heading}
            </TextAction>
          </HeadingText>
          <RefillScoreStatusBadge label={label} color={color} />
        </StackItem>
        <StackItem>
          <img src={image} alt="" width="84px" height="84px" />
        </StackItem>
      </StackLayout>
    </ElevatedCard>
  );
};
