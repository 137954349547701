import { useMutation } from 'react-query';
import { socketAsFetch } from 'common/utils/socket-as-fetch';
import { MUTATION_KEYS, MESSAGE_TYPES } from 'common/constants/api';

export const useUpdateUserEmail = () =>
  useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_USER_EMAIL],
    mutationFn: async (variables: { emailAddress: string }) => {
      try {
        return await socketAsFetch({
          message_type: MESSAGE_TYPES.LINK_EMAIL,
          info: {
            email: variables.emailAddress,
          },
        });
      } catch (e) {
        if (e instanceof Error) {
          throw new Error(e.message);
        }
        throw new Error("Failed to update the user's email");
      }
    },
  });
