import { useUpdateUserProfile } from 'hooks/use-set-user-profile/use-set-user-profile.hook';
import { PHONE_NUMBER_TYPES } from 'common/constants/account-info';
import { useUpdateUserEmail } from 'hooks/use-update-user-email/use-update-user-email.hook';

export type AccountSetupInputs = {
  mobilePhoneNumber: string;
  homePhoneNumber: string;
  email: string;
  isTextNotificationsEnabled: boolean;
};

export const useUpdateAccountSetup = () => {
  const { updateUserProfile } = useUpdateUserProfile();
  const updateUserEmailMutation = useUpdateUserEmail();

  const updateAccountSetup = async (values: AccountSetupInputs) => {
    const {
      mobilePhoneNumber,
      homePhoneNumber,
      email,
      isTextNotificationsEnabled,
    } = values;
    const data = {
      phoneNumbers: [
        {
          description: PHONE_NUMBER_TYPES.MOBILE,
          number: mobilePhoneNumber,
        },
        {
          description: PHONE_NUMBER_TYPES.HOME,
          number: homePhoneNumber,
        },
      ],
      email,
      optedIntoMarketingCommunications: isTextNotificationsEnabled,
      notificationPreferences: { disableSms: !isTextNotificationsEnabled },
    };
    await updateUserEmailMutation.mutateAsync({
      emailAddress: email,
    });
    return updateUserProfile(data);
  };
  return {
    updateAccountSetup,
  };
};
