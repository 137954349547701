import { useEffect, useState } from 'react';

export type MobileAuthPayload = {
  accessToken: string;
  user: {
    'https://el/user_id': string;
  };
};

// Set up the window to expect a global __LEAGUE_AUTH__ variable
declare global {
  interface Window {
    __LEAGUE_AUTH__: { send: (state: MobileAuthPayload) => void };
  }
}

const shouldUseMobileAuth: boolean = document.cookie.includes(
  'waitForMobileAuth=true',
);

// Utility function to persist and retrieve mobile auth payload from local storage
const persistPayload = (config: MobileAuthPayload) => {
  localStorage.setItem('authConfig', JSON.stringify(config));
};

const getPersistedPayload = (): MobileAuthPayload | undefined => {
  const storedConfig = localStorage.getItem('authConfig');
  return storedConfig ? JSON.parse(storedConfig) : undefined;
};

const LeagueAuthListenerReadyEvent = new Event('league-auth-listener-ready');

/**
 * Create a mobile auth listener that checks for the `waitForMobileAuth` cookie and provides a callback
 * for mobile to send authentication information in a mobile-app-hosted embedded webview
 */
export const useMobileAuthListener = () => {
  const [mobileAuthPayload, setMobileAuthPayload] = useState<
    MobileAuthPayload | undefined
  >(getPersistedPayload);

  useEffect(() => {
    // If we're in mobile auth mode and need to wait for the payload
    if (shouldUseMobileAuth) {
      // eslint-disable-next-line no-underscore-dangle
      window.__LEAGUE_AUTH__ = {
        send: (state: MobileAuthPayload) => {
          persistPayload(state);
          setMobileAuthPayload(state);
        },
      };
      window.dispatchEvent(LeagueAuthListenerReadyEvent);
    }
  }, []);

  return {
    shouldUseMobileAuth,
    mobileAuthPayload,
  };
};
