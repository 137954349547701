import React from 'react';
import {
  Box,
  HtmlToReact,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';
import { AuthorizationDetailsInfo } from 'common/types/types';

type AuthorizationSectionContainerProps = {
  heading?: React.ReactElement;
  info: AuthorizationDetailsInfo;
};

export const AuthorizationSectionContainer = ({
  info,
  heading,
}: AuthorizationSectionContainerProps) => {
  const isBelowTablet = useIsBelowTablet();

  return (
    <StackLayout
      css={{
        borderWidth: '$thin',
        borderStyle: 'solid',
        borderColor: '$onSurfaceBorderSubdued',
        borderRadius: '$large',
        padding: '$two',
        marginBlockStart: '$half',
      }}
      spacing="$one"
      horizontalAlignment="stretch"
    >
      {heading}
      <Box
        css={{
          display: 'grid',
          gridTemplateColumns: isBelowTablet ? '1fr' : '1fr 1fr 1fr',
          gap: '$one',
        }}
      >
        {info.map(({ key, value }) => (
          <StackLayout key={key.replace(' ', '')} spacing="$quarter">
            <UtilityText css={{ typography: '$subtitleTwo' }}>
              {key}
            </UtilityText>
            <UtilityText css={{ typography: '$bodyOne' }}>
              <HtmlToReact htmlString={value.replace(/\n/g, '<br>')} />
            </UtilityText>
          </StackLayout>
        ))}
      </Box>
    </StackLayout>
  );
};
