import type {
  MasonryEngineNodeRendererProps,
  MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { Accordion } from '@leagueplatform/web-common-components';

type LearnMoreAccordionNodeProperties = {
  headerButtonLabel: string;
  items: Array<MasonryEngineNode>;
};

type LearnMoreAccordionLinkNode = MasonryEngineNode<
  'authorizationLearnMoreAccordion',
  LearnMoreAccordionNodeProperties
>;

type LearnMoreAccordionLinkProps =
  MasonryEngineNodeRendererProps<LearnMoreAccordionLinkNode>;

export const LearnMoreAccordion = ({
  headerButtonLabel,
  items,
}: LearnMoreAccordionLinkProps) => (
  <Accordion.Root collapsible defaultValue="" type="single">
    <Accordion.Item value="learn-more">
      <Accordion.Header>
        <Accordion.Trigger
          css={{
            '& > span': {
              alignItems: 'center',
              justifyContent: 'flex-start',
            },
            '.cc-accordion-trigger-icon': {
              width: 16,
              height: 16,
              marginInlineStart: '$quarter',
            },
            '&&': {
              width: 'auto',
              paddingInlineEnd: '$half',
            },
          }}
        >
          <UtilityText css={{ typography: '$buttonTwo' }}>
            {headerButtonLabel}
          </UtilityText>
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <StackLayout spacing="$one">{items}</StackLayout>
      </Accordion.Content>
    </Accordion.Item>
  </Accordion.Root>
);
