import {
  HeadingText,
  ParagraphText,
  Popover,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { type MasonryNodeAnalytics } from '@leagueplatform/masonry-api';
import type {
  MasonryEngineNode,
  MasonryEngineNodeAction,
  MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';

import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';

type ContactScanHeadingWithTooltipProperties = {
  heading: string;
};

type MasonrySimpleTooltipAction = MasonryEngineNodeAction<
  'simpleTooltip',
  {
    body: string;
  },
  MasonryNodeAnalytics
>;

type ContactScanHeadingWithTooltipActions = {
  onClick?: MasonrySimpleTooltipAction;
};

type ContactScanHeadingWithTooltipNode = MasonryEngineNode<
  'headingWithIcon',
  ContactScanHeadingWithTooltipProperties,
  ContactScanHeadingWithTooltipActions
>;

type ContactScanHeadingWithTooltipProps =
  MasonryEngineNodeRendererProps<ContactScanHeadingWithTooltipNode>;

export const ContactScanHeadingWithTooltip = ({
  heading,
  onClick,
}: ContactScanHeadingWithTooltipProps) => {
  const { formatMessage } = useIntl();
  const isBelowTablet = useIsBelowTablet();
  const tooltipContent = onClick?.payload?.body || '';

  return (
    <StackLayout orientation="horizontal" verticalAlignment="center">
      <HeadingText level="3" size="lg">
        {heading}
      </HeadingText>
      <Popover.Root>
        <Popover.Trigger
          label={formatMessage({ id: 'STR_TEXT_MEMBER_SERVICES' })}
          icon="tinyHelper"
          priority="secondary"
          quiet
          hideLabel
          css={{ marginInlineStart: '$quarter' }}
        />
        <Popover.Content
          side={isBelowTablet ? 'bottom' : 'right'}
          align="center"
          css={{ maxWidth: '367px', marginInline: '$half' }}
          headingBar={false}
          showCloseButton={false}
        >
          <UtilityText
            as="fieldset"
            emphasis="emphasized"
            css={{
              paddingBlockEnd: '$half',
              display: 'block',
            }}
          >
            {formatMessage({ id: 'STR_TEXT_MEMBER_SERVICES' })}
          </UtilityText>
          <ParagraphText>{tooltipContent}</ParagraphText>
        </Popover.Content>
      </Popover.Root>
    </StackLayout>
  );
};
