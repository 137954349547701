import { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  ImageMessage,
  StackLayout,
  HeadingText,
  ParagraphText,
  Box,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { logout } from 'common/utils/logout';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';
import { useDeleteAccountMutation } from 'hooks/use-delete-account-mutation/use-delete-account-mutation.hook';
import EXIT from 'assets/exit-warning-illustration.png';
import PATH from 'assets/path.png';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AccountDeletionModal = ({ isOpen, onClose }: ModalProps) => {
  const { formatMessage } = useIntl();
  const [confirmationDeletionModal, setConfirmationDeletionModal] =
    useState(false);
  const { mutateAsync: deleteAccount, isLoading: isDeletingAccount } =
    useDeleteAccountMutation();

  useEffect(() => {
    if (isOpen) setConfirmationDeletionModal(false);
  }, [isOpen]);

  const handleDeleteAccount = async () => {
    await deleteAccount(
      {},
      {
        onSuccess: () => {
          setConfirmationDeletionModal(true);
        },
      },
    );
  };

  const isBelowTablet = useIsBelowTablet();

  const handleCloseModal = () => {
    onClose();
    if (confirmationDeletionModal === true) {
      logout();
    }
  };
  return (
    <Modal.Root open={isOpen} onOpenChange={handleCloseModal}>
      <Modal.Content
        width={{
          '@initial': 580,
          '@mobile': '100%',
          '@mobileLandscape': '100%',
        }}
      >
        {!confirmationDeletionModal ? (
          <ImageMessage image={EXIT} imageGap="$oneAndHalf" imageWidth={160}>
            <StackLayout horizontalAlignment="stretch">
              <Modal.Title>
                <HeadingText
                  size="lg"
                  level="3"
                  css={{
                    marginBlockEnd: '$half',
                  }}
                >
                  {formatMessage({ id: 'STR_ACCOUNT_DELETION_MODAL_HEADING' })}
                </HeadingText>
              </Modal.Title>
              <Modal.Description>
                <>
                  <ParagraphText>
                    {formatMessage({ id: 'STR_ACCOUNT_DELETION_MODAL_BODY_1' })}
                  </ParagraphText>
                  <ParagraphText>
                    {formatMessage({ id: 'STR_ACCOUNT_DELETION_MODAL_BODY_2' })}
                  </ParagraphText>
                </>
              </Modal.Description>

              <StackLayout
                horizontalAlignment={isBelowTablet ? 'stretch' : 'end'}
              >
                <Box
                  css={{
                    display: 'flex',
                    flexDirection: isBelowTablet ? 'column-reverse' : '',
                    paddingBlockEnd: isBelowTablet ? '$five' : '$none',
                  }}
                >
                  <Modal.Close>
                    <Button
                      priority="secondary"
                      css={{
                        marginInlineEnd: '$one',
                        marginBlockStart: isBelowTablet
                          ? '$one'
                          : '$twoAndHalf',
                      }}
                      width={isBelowTablet ? 'fillContainer' : 'hugContents'}
                    >
                      {formatMessage({
                        id: 'STR_NEVERMIND',
                      })}
                    </Button>
                  </Modal.Close>
                  <Button
                    onClick={handleDeleteAccount}
                    disabled={isDeletingAccount}
                    loading={isDeletingAccount}
                    css={{
                      marginBlockStart: '$twoAndHalf',
                      backgroundColor: '$primaryBackgroundCriticalDefault',
                    }}
                    width={isBelowTablet ? 'fillContainer' : 'hugContents'}
                  >
                    {formatMessage({
                      id: 'STR_ACCOUNT_DELETION_MODAL_CONFIRMATION_BUTTON',
                    })}
                  </Button>
                </Box>
              </StackLayout>
            </StackLayout>
          </ImageMessage>
        ) : (
          <ImageMessage image={PATH} imageGap="$oneAndHalf" imageWidth={160}>
            <StackLayout horizontalAlignment="stretch">
              <Modal.Title>
                <HeadingText
                  size="lg"
                  level="3"
                  css={{
                    marginBlockEnd: '$half',
                  }}
                >
                  {formatMessage({ id: 'STR_ACCOUNT_NOW_DELETED' })}
                </HeadingText>
              </Modal.Title>
              <Modal.Description>
                <>
                  <ParagraphText>
                    {formatMessage({
                      id: 'STR_ACCOUNT_CONFIRMATION_DELETION_MODAL_BODY_1',
                    })}
                  </ParagraphText>
                  <ParagraphText>
                    {formatMessage({
                      id: 'STR_ACCOUNT_CONFIRMATION_DELETION_MODAL_BODY_2',
                    })}
                  </ParagraphText>
                </>
              </Modal.Description>

              <StackLayout
                horizontalAlignment={isBelowTablet ? 'center' : 'end'}
                css={{
                  paddingBlockEnd: isBelowTablet ? '$five' : '$none',
                }}
              >
                <Modal.Close>
                  <Button
                    priority="primary"
                    css={{
                      marginBlockStart: '$twoAndHalf',
                    }}
                    width={isBelowTablet ? 'fillContainer' : 'hugContents'}
                  >
                    {formatMessage({
                      id: 'STR_I_UNDERSTAND',
                    })}
                  </Button>
                </Modal.Close>
              </StackLayout>
            </StackLayout>
          </ImageMessage>
        )}
      </Modal.Content>
    </Modal.Root>
  );
};
