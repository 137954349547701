import {
  StatusBadge,
  type GDSStatusBadgeProps,
  Box,
} from '@leagueplatform/genesis-core';
import type { RefillScoreStatusColors } from 'common/types/types';

type RefillScoreStatusBadgeProps = {
  label: string;
  color: RefillScoreStatusColors;
};

const RefillScoreStatusBadgeMap: Record<
  RefillScoreStatusColors,
  { status: GDSStatusBadgeProps['status'] }
> = {
  green: {
    status: 'success',
  },
  yellow: {
    status: 'warning',
  },
  red: {
    status: 'error',
  },
  '#AAD2A0': {
    status: 'success',
  },
  '#FFD092': {
    status: 'warning',
  },
  '#FFB7B7': {
    status: 'error',
  },
};

export const RefillScoreStatusBadge = ({
  label,
  color,
}: RefillScoreStatusBadgeProps) => {
  const { status: badgeStatus } = RefillScoreStatusBadgeMap[color];

  return (
    <Box>
      <StatusBadge label={label} status={badgeStatus} showLight={false} />
    </Box>
  );
};
