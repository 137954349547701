import { StackLayout } from '@leagueplatform/genesis-core';
import {
  type MasonryEngineNodeAction,
  type MasonryEngineActionHandler,
  type MasonryEngineNodeRendererProps,
  type MasonryEngineNode,
  useMasonryEngineActionEmitter,
} from '@leagueplatform/masonry-engine';
import { Pagination } from '@leagueplatform/genesis-core-alpha';
import { MASONRY_NAMESPACES } from 'common/constants/masonry';

type SetPageAction = MasonryEngineNodeAction<
  'setPage',
  {
    nodeId: string;
    page: number;
  }
>;

const getSetPageAction = (nodeId: string, page: number): SetPageAction => ({
  type: 'setPage',
  namespace: MASONRY_NAMESPACES.PRIOR_AUTHORIZATIONS_STATUS,
  payload: {
    nodeId,
    page,
  },
});

export const setPageActionHandler: MasonryEngineActionHandler<SetPageAction> = (
  { payload },
  // this handler doesn't need the `actionEmitter` or the `stateController`.
  _,
  __,
  messagingController,
) => {
  messagingController.sendMessage(payload.nodeId, {
    type: 'reload',
    payload: {
      setQueryParams: (current) => ({
        ...current,
        page: String(payload.page),
      }),
    },
  });
};

type PaginationLayoutNodeProperties = {
  items: Array<MasonryEngineNode>;
  currentPage: number;
  totalPages: number;
};

type PaginationLayoutNode = MasonryEngineNode<
  'paginationLayout',
  PaginationLayoutNodeProperties
>;

type PaginationLayoutProps =
  MasonryEngineNodeRendererProps<PaginationLayoutNode>;

export const PaginationLayout = ({
  items,
  nodeId,
  currentPage,
  totalPages,
}: PaginationLayoutProps) => {
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <StackLayout horizontalAlignment="stretch">
      {items}
      <Pagination
        defaultPage={1}
        onPageChange={(selectedPage) => {
          invokeAction(getSetPageAction(nodeId, selectedPage.pageNumber));
        }}
        page={currentPage}
        totalPages={totalPages}
        css={{ justifyContent: 'center', marginBlockStart: '$two' }}
      />
    </StackLayout>
  );
};
