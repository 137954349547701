import { isEmail, isPhoneNumber } from '@leagueplatform/web-common';

/* https://react-hook-form.com/docs/useform/register
   We need to check against an empty value because the `validate` method...
   "... will be executed on its own without depending on other validation rules included in the `required` attribute."
*/

export const phoneNumberValidation = (errorMsg: string) => ({
  validate: {
    validPhoneNumber: (value: string) => {
      if (value.length > 0) return isPhoneNumber(value) || errorMsg;
      return true;
    },
  },
});

export const emailValidation = (errorMsg: string) => ({
  validate: {
    validEmail: (value: string) => {
      if (value.length > 0) return isEmail(value) || errorMsg;
      return true;
    },
  },
});
