import {
  MasonryDriverActionController,
  MasonryDriverNodeRenderersRegistry,
} from '@leagueplatform/masonry';
import {
  MASONRY_NAMESPACES,
  MASONRY_WIDGET_TYPES,
  MASONRY_ACTION_TYPES,
} from 'common/constants/masonry';
import { PrescriptionsRichText } from 'components/prescriptions/renderers/prescriptions-rich-text.renderer';
import {
  setPageActionHandler,
  PaginationLayout,
} from 'components/masonry-renderers/pagination-layout.renderer';
import { PrescriptionKeyValueDescription } from 'components/prescriptions/renderers/prescription-key-value-description.renderer';
import { PrescriptionDetailsSection } from 'components/prescriptions/renderers/prescription-details-section.renderer';
import { PageHeaderRenderer } from 'components/masonry-renderers/page-header.renderer';

export const initPrescriptionsRegistry = () => {
  // List Page
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRESCRIPTIONS,
    MASONRY_WIDGET_TYPES.PAGINATION_LAYOUT,
    PaginationLayout,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRESCRIPTIONS,
    MASONRY_WIDGET_TYPES.SMALL_CARD,
    MasonryDriverNodeRenderersRegistry.getNodeRenderersMap().default.smallCard,
  );

  MasonryDriverActionController.registerHandler(
    'setPage',
    MASONRY_NAMESPACES.PRESCRIPTIONS,
    setPageActionHandler,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRESCRIPTIONS,
    MASONRY_WIDGET_TYPES.RICH_TEXT,
    PrescriptionsRichText,
  );

  MasonryDriverActionController.registerHandler(
    MASONRY_ACTION_TYPES.DEEPLINK,
    MASONRY_NAMESPACES.PRESCRIPTIONS,
    () => {},
  );

  // Details Page
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRESCRIPTION_DETAILS,
    MASONRY_WIDGET_TYPES.SECTION_HEADER,
    PageHeaderRenderer,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRESCRIPTION_DETAILS,
    MASONRY_WIDGET_TYPES.KEY_VALUE_HEADING_DESCRIPTION,
    PrescriptionKeyValueDescription,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRESCRIPTION_DETAILS,
    MASONRY_WIDGET_TYPES.GRID_LAYOUT,
    PrescriptionDetailsSection,
  );
};
