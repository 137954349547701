import type {
  MasonryEngineNodeRendererProps,
  MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { UtilityText, StackLayout } from '@leagueplatform/genesis-core';
import { HtmlToReact } from '@leagueplatform/web-common';
import type { RefillScoreStatusColors } from 'common/types/types';
import { RefillScoreStatusBadge } from 'components/refill-score/refill-score-status-badge.component';

type RefillScoreLearnMoreDefinitionLinkNodeProperties = {
  description: string;
  heading: string;
  color: RefillScoreStatusColors;
};

type RefillScoreLearnMoreDefinitionLinkNode = MasonryEngineNode<
  'learnMoreDefinition',
  RefillScoreLearnMoreDefinitionLinkNodeProperties
>;

type RefillScoreLearnMoreDefinitionLinkProps =
  MasonryEngineNodeRendererProps<RefillScoreLearnMoreDefinitionLinkNode>;

export const RefillScoreLearnMoreDescription = ({
  color,
  description,
  heading,
}: RefillScoreLearnMoreDefinitionLinkProps) => (
  <StackLayout spacing="$threeQuarters" css={{ marginBlockStart: '$quarter' }}>
    <RefillScoreStatusBadge color={color} label={heading} />
    <HtmlToReact
      htmlString={description}
      options={{
        span: {
          component: UtilityText as React.FunctionComponent,
          props: {
            css: {
              typography: '$bodyTwo',
              paddingBlockStart: '$none',
              paddingBlockEnd: '$none',
              color: '#2C3135',
            },
          },
        },
      }}
    />
  </StackLayout>
);
