export const CTA_FOOTER_HEIGHT = '98px';
export const ACCOUNT_SETUP_CONTENT_MAX_WIDTH = '708px';
export const CONTENT_MAX_WIDTH = 952;
export const BACK_BUTTON_WIDTH = 125;
export const PAGE_NAVIGATION_WIDTH = 1178;
export const PAGE_NAVIGATION_HEIGHT = 8 * 7; // 56px – 7 lines on the Genesis 8px grid
export const HERO_BANNER_HEIGHT = 184;
export const ELEMENT_IDS = {
  PAGE_HEADER: 'page-header',
};

export const FORM_LEGEND_STYLE = {
  '.GDS-fieldset-legend': {
    typography: '$headingFour',
    marginBlockEnd: '$one',
    color: '$onSurfaceTextPrimary',
  },
};
