import type {
  MasonryEngineNode,
  MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import {
  HeadingText,
  HtmlToReact,
  ImageMessage,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { ContentSection } from 'components/content-section/content-section.component';

type AuthorizationEmptyStateProperties = {
  body: string;
  heading: string;
  image: string;
};

type AuthorizationEmptyStateNode = MasonryEngineNode<
  'authorizationsEmptyState',
  AuthorizationEmptyStateProperties
>;

type AuthorizationEmptyStateRenderer =
  MasonryEngineNodeRendererProps<AuthorizationEmptyStateNode>;

export const AuthorizationEmptyState = ({
  body,
  heading,
  image,
}: AuthorizationEmptyStateRenderer) => (
  <ContentSection
    horizontalAlignment="center"
    css={{ height: 'auto', margin: '$none' }}
  >
    <ImageMessage image={image} imageWidth={140}>
      <StackLayout
        horizontalAlignment="center"
        spacing="$one"
        css={{ textAlign: 'center' }}
      >
        <HeadingText level="2" size="lg">
          {heading}
        </HeadingText>
        <UtilityText
          css={{ typography: '$bodyOne', color: '$onSurfaceTextSubdued' }}
        >
          <HtmlToReact htmlString={body} />
        </UtilityText>
      </StackLayout>
    </ImageMessage>
  </ContentSection>
);
