import React from 'react';
import {
  HeadingText,
  ParagraphText,
  Button,
  StackLayout,
  StackItem,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { BasePage } from 'components/base-page.component';
import HighFiveCelebrationIllustration from 'assets/high-five-celebration-illustration.png';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { ROUTE_PATHS } from 'common/constants/constants';
import { ACCOUNT_SETUP_CONTENT_MAX_WIDTH } from 'common/constants/ui';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';

export const AccountSetupCompletePage = ({
  onOnboardingComplete,
}: {
  onOnboardingComplete: () => void;
}) => {
  const { formatMessage } = useIntl();
  const isBelowTablet = useIsBelowTablet();

  return (
    <BasePage
      showNavBar={false}
      css={{
        padding: isBelowTablet ? '$none' : '$four',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...(isBelowTablet && {
          '.wrapper': {
            height: '100%',
          },
        }),
      }}
    >
      <StackItem
        css={{
          background: '$surfaceCardSecondary',
          boxShadow: '$card',
          maxWidth: isBelowTablet ? '100%' : ACCOUNT_SETUP_CONTENT_MAX_WIDTH,
          height: isBelowTablet ? '100%' : 'fit-content',
          paddingBlock: isBelowTablet ? '$five' : '$twoAndHalf',
          paddingInline: isBelowTablet ? '$two' : '$five',
          gap: '$two',
        }}
        horizontalAlignment="center"
        verticalAlignment="center"
      >
        <img src={HighFiveCelebrationIllustration} aria-hidden="true" alt="" />
        <StackItem>
          <StackLayout spacing="$half" horizontalAlignment="center">
            <HeadingText level="1" size="lg">
              {formatMessage({ id: 'STR_ACCOUNT_SETUP_COMPLETE_HEADING' })}
            </HeadingText>
            <ParagraphText css={{ textAlign: 'center' }}>
              {formatMessage({ id: 'STR_ACCOUNT_SETUP_COMPLETE_BODY' })}
            </ParagraphText>
          </StackLayout>
        </StackItem>
        <StackItem css={{ width: '100%' }}>
          <StackLayout spacing="$oneAndHalf" horizontalAlignment="center">
            <Button
              as="a"
              width="fillContainer"
              onClick={() => onOnboardingComplete()}
              href={getModulePath(LEAGUE_MODULE_NAMES.assessment)}
              textAlign="center"
            >
              {formatMessage({ id: 'STR_START_HEALTH_ASSESSMENT' })}
            </Button>
            <Button
              as="a"
              width="fillContainer"
              quiet
              priority="tertiary"
              onClick={() => onOnboardingComplete()}
              href={ROUTE_PATHS.HOME}
            >
              {formatMessage({ id: 'STR_NOT_NOW' })}
            </Button>
          </StackLayout>
        </StackItem>
      </StackItem>
    </BasePage>
  );
};
