import {
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
  useMasonryEngineActionEmitter,
} from '@leagueplatform/masonry-engine';
import { Button, Box } from '@leagueplatform/genesis-core';
import type { MasonryDeeplinkAction } from '@leagueplatform/masonry-renderers';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';

type AuthorizationDetailsButtonProperties = {
  label: string;
};

type AuthorizationDetailsButtonActions = {
  onClick: MasonryDeeplinkAction;
};

type AuthorizationDetailsButtonNode = MasonryEngineNode<
  'button',
  AuthorizationDetailsButtonProperties,
  AuthorizationDetailsButtonActions
>;

type AuthorizationDetailsButtonProps =
  MasonryEngineNodeRendererProps<AuthorizationDetailsButtonNode>;

export const AuthorizationDetailsButton = ({
  label,
  onClick,
}: AuthorizationDetailsButtonProps) => {
  const isBelowTablet = useIsBelowTablet();
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <Box
      css={
        isBelowTablet
          ? {
              '&&': {
                flexBasis: 0,
              },
            }
          : undefined
      }
    >
      <Button
        href={onClick?.payload.url}
        onClick={(event: React.MouseEvent) => {
          if (onClick) {
            invokeAction({
              event,
              ...onClick,
            });
          }
        }}
        as="a"
        width="fillContainer"
      >
        {label}
      </Button>
    </Box>
  );
};
