import { type MasonryDeeplinkAction } from '@leagueplatform/masonry-renderers';
import {
  MasonryEngineNodeRendererProps,
  useMasonryEngineActionEmitter,
  type MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import {
  TextAction,
  HtmlToReact,
  Icon,
  Modal,
  Box,
  Divider,
} from '@leagueplatform/genesis-core';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';

type LearnMoreActionLinkNodeProperties = {
  body: string;
  isVisible: boolean;
  leftImage: string;
  rightImage: string;
};

type LearnMoreActionLinkNodeActions = {
  onClick: MasonryDeeplinkAction;
};

type LearnMoreActionLinkNode = MasonryEngineNode<
  'authorizationLearnMoreCard',
  LearnMoreActionLinkNodeProperties,
  LearnMoreActionLinkNodeActions
>;

type LearnMoreActionLinkProps =
  MasonryEngineNodeRendererProps<LearnMoreActionLinkNode>;

export const LearnMoreActionLink = ({
  body,
  rightImage,
  onClick,
}: LearnMoreActionLinkProps) => {
  const isBelowTablet = useIsBelowTablet();
  const invokeAction = useMasonryEngineActionEmitter();

  if (!isBelowTablet) return <Box css={{ padding: '$half' }} />;

  return (
    <Box
      css={{
        marginBlockEnd: '$one',
        paddingBlock: '$one',
      }}
    >
      <Modal.Trigger>
        <TextAction
          as="button"
          css={{
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '$onSurfaceTextSubdued',
            width: '100%',
          }}
          onClick={(event: React.MouseEvent) => {
            if (onClick) {
              invokeAction({
                event,
                ...onClick,
              });
            }
          }}
        >
          <HtmlToReact htmlString={body} />
          <Icon
            icon={rightImage}
            css={{ width: 6, height: 12, marginInlineStart: '$half' }}
          />
        </TextAction>
      </Modal.Trigger>
      <Divider
        css={{
          paddingBlockStart: '$half',
          position: 'absolute',
          width: '100%',
          left: 0,
        }}
      />
    </Box>
  );
};
