import { HeadingText } from '@leagueplatform/genesis-core';
import type {
  MasonryEngineNodeRendererProps,
  MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import type {
  PriorAuthorizationStatuses,
  AuthorizationDetailsInfo,
} from 'common/types/types';
import { AuthorizationStatusBadge } from 'components/prior-authorization-status/authorization-status-badge.component';
import { AuthorizationSectionContainer } from 'components/prior-authorization-status/authorization-section-container.component';

type AuthorizationServiceCardProperies = {
  status: PriorAuthorizationStatuses;
  heading: string;
  info: AuthorizationDetailsInfo;
};

type AuthorizationServiceCardNode = MasonryEngineNode<
  'authorizationServiceCard',
  AuthorizationServiceCardProperies
>;

type AuthorizationServiceCardProps =
  MasonryEngineNodeRendererProps<AuthorizationServiceCardNode>;

export const AuthorizationServiceCard = ({
  status,
  heading,
  info,
}: AuthorizationServiceCardProps) => (
  <AuthorizationSectionContainer
    heading={
      <>
        <AuthorizationStatusBadge status={status} showLight />
        <HeadingText level="3" size="sm" css={{ typography: '$subtitleOne' }}>
          {heading}
        </HeadingText>
      </>
    }
    info={info}
  />
);
