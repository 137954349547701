import {
  StackLayout,
  Card,
  Box,
  UtilityText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { RefillScoreStatusBadge } from 'components/refill-score/refill-score-status-badge.component';
import type {
  MasonryEngineNode,
  MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import type { RefillScoreStatusColors } from 'common/types/types';

type RefillScoreBadgeImageSummaryNodeProperties = {
  body: string;
  color: RefillScoreStatusColors;
  footer: string;
  image: string;
  label: string;
  heading: string;
};

type RefillScoreBadgeImageSummaryNode = MasonryEngineNode<
  'badgeImageSummary',
  RefillScoreBadgeImageSummaryNodeProperties
>;

type RefillScoreBadgeImageSummaryProps =
  MasonryEngineNodeRendererProps<RefillScoreBadgeImageSummaryNode>;

export const RefillScoreBadgeImageSummary = ({
  body,
  color,
  footer,
  image,
  label,
  heading,
}: RefillScoreBadgeImageSummaryProps) => (
  <>
    <Box css={{ position: 'relative', width: 83, margin: 'auto' }}>
      <Box css={{ position: 'absolute', top: '-41.5px', left: 0, zIndex: '1' }}>
        <img src={image} alt="" />
      </Box>
    </Box>
    <Card.Flat
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '0px 2px 6px 0px #0000001F',
        borderRadius: '8px',
        border: 'none',
        backgroundColor: '$surfaceCardSecondary',
        width: '100%',
        paddingInline: '$one',
        paddingBlock: '$two',
        gap: '$threeQuarters',
        paddingBlockStart: 'calc($threeQuarters + 41.5px)',
        textAlign: 'center',
      }}
    >
      <StackLayout spacing="$quarter" horizontalAlignment="center">
        <UtilityText as="h2" emphasis="base" size="eyebrow">
          {heading}
        </UtilityText>
        <RefillScoreStatusBadge label={label} color={color} />
      </StackLayout>
      <ParagraphText css={{ '&&': { marginBlockEnd: '$none' } }}>
        {body}
      </ParagraphText>
      <UtilityText emphasis="subtle" size="sm">
        {footer}
      </UtilityText>
    </Card.Flat>
  </>
);
