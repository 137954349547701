import { initDefaultWSRegistry } from 'masonry-registries/default.registry';
import { initPriorAuthorizationStatusRegistry } from 'masonry-registries/prior-auth-status.registry';
import { initPrescriptionsRegistry } from 'masonry-registries/prescriptions.registry';
import { initRefillScoreRegistry } from 'masonry-registries/refill-score.registry';
import { initContactScanRegistry } from './contact-scan.registry';

initDefaultWSRegistry();
initPriorAuthorizationStatusRegistry();
initPrescriptionsRegistry();
initRefillScoreRegistry();
initContactScanRegistry();
