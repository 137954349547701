import {
  Box,
  HeadingText,
  StackLayout,
  Button,
  type GDSColor,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useHistory } from '@leagueplatform/routing';
import { CONTENT_MAX_WIDTH, BACK_BUTTON_WIDTH } from 'common/constants/ui';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';
import React from 'react';

interface PageHeaderProps {
  text?: string;
  historyGoBack?: boolean;
  onBackClick?: () => void;
  backgroundColor?: GDSColor;
  subHeading?: React.ReactElement;
  maxWidth?: number;
}

export const PageHeader = ({
  text,
  historyGoBack,
  onBackClick,
  backgroundColor,
  subHeading,
  maxWidth = CONTENT_MAX_WIDTH,
}: PageHeaderProps) => {
  const isBelowTablet = useIsBelowTablet();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const showBackButton = Boolean(historyGoBack || onBackClick);

  return (
    <Box
      css={{
        backgroundColor: backgroundColor || '$surfaceBackgroundTertiary',
        height: isBelowTablet ? 'auto' : 140,
        paddingInline: '$two',
        paddingBlock: isBelowTablet ? '$two' : '$none',
      }}
    >
      <StackLayout
        orientation={isBelowTablet ? 'vertical' : 'horizontal'}
        verticalAlignment="center"
        css={{
          maxWidth: showBackButton
            ? `calc(${maxWidth}px - $two + ${BACK_BUTTON_WIDTH}px)`
            : maxWidth,
          marginInline: 'auto',
          height: '100%',
        }}
      >
        {showBackButton && (
          <Button
            onClick={onBackClick || history.goBack}
            quiet
            priority="tertiary"
            icon="interfaceArrowLeft"
            size="medium"
            hideLabel
            css={{
              marginInlineEnd: isBelowTablet ? '$none' : '$oneAndHalf',
              position: isBelowTablet ? 'relative' : 'unset',
              left: isBelowTablet ? 'calc(0px - $half)' : 0,
            }}
          >
            {formatMessage({ id: 'STR_GO_BACK' })}
          </Button>
        )}
        <StackLayout
          verticalAlignment="center"
          spacing={isBelowTablet ? '$half' : '$none'}
          horizontalAlignment="stretch"
        >
          <HeadingText level="1" size={isBelowTablet ? 'xl' : 'xxl'}>
            {text}
          </HeadingText>
          {subHeading}
        </StackLayout>
      </StackLayout>
    </Box>
  );
};
