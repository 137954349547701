import { PageHeader } from 'components/page-header/page-header.component';
import type {
  MasonryEngineNode,
  MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { createPortal } from 'react-dom';
import { ELEMENT_IDS } from 'common/constants/ui';

export type PageHeaderNodeProperties = {
  heading: string;
};

export type PageHeaderNode = MasonryEngineNode<
  'pageHeader',
  PageHeaderNodeProperties
>;

export type PageHeaderProps = MasonryEngineNodeRendererProps<PageHeaderNode>;

export const PageHeaderRenderer = ({ heading }: PageHeaderProps) => {
  const container = document.getElementById(ELEMENT_IDS.PAGE_HEADER);
  const PageHeaderComponent = <PageHeader text={heading} historyGoBack />;

  if (container !== null) {
    return createPortal(PageHeaderComponent, container);
  }

  return PageHeaderComponent;
};
