import {
  MasonryDriverActionController,
  MasonryDriverNodeRenderersRegistry,
} from '@leagueplatform/masonry';
import {
  MASONRY_ACTION_TYPES,
  MASONRY_NAMESPACES,
  MASONRY_WIDGET_TYPES,
} from 'common/constants/masonry';
import { ContactScanDescription } from 'components/contact-scan/renderers/contact-scan-description.renderer';
import { ContactScanHeadingWithTooltip } from 'components/contact-scan/renderers/contact-scan-heading-tooltip.renderer';

export const initContactScanRegistry = () => {
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.CONTACT_SCAN,
    MASONRY_WIDGET_TYPES.DESCRIPTIVE_SECTION_WITH_IMAGE,
    ContactScanDescription,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.CONTACT_SCAN,
    MASONRY_WIDGET_TYPES.HEADING_WITH_ICON,
    ContactScanHeadingWithTooltip,
  );

  MasonryDriverActionController.registerHandler(
    MASONRY_ACTION_TYPES.DEEPLINK,
    MASONRY_NAMESPACES.CONTACT_SCAN,
    () => {},
  );
};
