import React, { useState, useEffect, useRef } from 'react';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  HeadingText,
  StackLayout,
  TextAction,
  UtilityText,
} from '@leagueplatform/genesis-core';
import {
  LoadingIndicator,
  Switch,
} from '@leagueplatform/web-common-components';
import {
  usePageViewAnalytics,
  SCREEN_NAMES,
  PRODUCT_AREA,
  trackAnalyticsEvent,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { Link } from '@leagueplatform/routing';
import { PageHeader } from 'components/page-header/page-header.component';
import { ContentSection } from 'components/content-section/content-section.component';
import { TextCommunicationTermsModal } from 'components/modals/text-communication-terms/text-communication-terms.component';

import { CONTENT_MAX_WIDTH } from 'common/constants/ui';
import { ROUTE_PATHS } from 'common/constants/constants';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';
import { useGetScanUserProfile } from 'hooks/use-get-scan-user-profile/use-get-scan-user-profile.hook';
import { useUpdateUserProfile } from 'hooks/use-set-user-profile/use-set-user-profile.hook';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';

const TextNotificationsLabel = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <UtilityText
        css={{
          display: 'block',
          marginBlockEnd: '$half',
        }}
        emphasis="emphasized"
      >
        {formatMessage({ id: 'STR_ALLOW_TEXT_NOTIFICATIONS' })}
      </UtilityText>
      <UtilityText>
        {formatMessage({ id: 'STR_TEXT_INVITATIONS_AND_UPDATES' })}
      </UtilityText>
    </>
  );
};

export const CommunicationPreferencesPage = () => {
  const isBelowTablet = useIsBelowTablet();
  const { formatMessage } = useIntl();
  const hasMounted = useRef(false);

  useDocumentTitle(formatMessage({ id: 'STR_COMMUNICATION_PREFERENCES' }));
  usePageViewAnalytics({
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.COMMUNICATION_PREFERENCES,
  });

  const { updateUserProfile } = useUpdateUserProfile({
    onSuccess: () => {
      toastActions.add({
        type: TOAST_STATUS.SUCCESS,
        textId: 'STR_TEXT_PREFERENCE_UPDATED',
      });
    },
    onError: () => {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'STR_TEXT_PREFERENCE_UPDATED_FAILED',
      });
    },
  });

  const { data: { userProfile } = {}, isLoading } = useGetScanUserProfile();
  const isTextNotificationsDisabled =
    userProfile?.notificationPreferences?.disableSms;

  const [textNotificationSwitch, setTextNotificationSwitch] = useState<boolean>(
    !isTextNotificationsDisabled,
  );
  const [
    isTextCommunicationTermsModalOpen,
    setIsTextCommunicationTermsModalOpen,
  ] = useState<boolean>(false);

  const handleToggleTextNotification = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.SETTINGS,
      screen_name: SCREEN_NAMES.COMMUNICATION_PREFERENCES,
      detail: 'text notification toggle',
      toggle_status: !textNotificationSwitch ? 'on' : 'off',
    });

    // Open the modal when the switch goes from false to true
    if (textNotificationSwitch === false) {
      setIsTextCommunicationTermsModalOpen(true);
    } else {
      setTextNotificationSwitch((prev: boolean) => !prev);
    }
  };

  useEffect(() => {
    if (hasMounted.current) {
      updateUserProfile({
        optedIntoMarketingCommunications: textNotificationSwitch,
        notificationPreferences: { disableSms: !textNotificationSwitch },
      });
    } else {
      hasMounted.current = true;
    }
  }, [textNotificationSwitch, updateUserProfile]);

  if (isLoading || isTextNotificationsDisabled === undefined)
    return <LoadingIndicator />;

  return (
    <Box
      css={{
        display: 'grid',
        gridGap: '$three',
        gridTemplateRows: 'auto 1fr',
        paddingBlockEnd: isBelowTablet ? undefined : '$three',
      }}
    >
      <PageHeader
        text={formatMessage({ id: 'STR_COMMUNICATION_PREFERENCES' })}
      />
      <Box css={{ paddingInline: '$two', paddingBlockEnd: '$five' }}>
        <Box
          css={{
            display: isBelowTablet ? 'flex' : 'grid',
            flexDirection: isBelowTablet ? 'column' : undefined,
            gridTemplateColumns: '3fr 2fr',
            justifyContent: 'start',
            gap: '$oneAndHalf',
            alignItems: isBelowTablet ? 'stretch' : 'start',
            maxWidth: CONTENT_MAX_WIDTH,
            marginInline: 'auto',
          }}
        >
          <ContentSection
            css={{
              paddingInlineStart: '$two',
              height: 'auto',
              margin: 0,
            }}
            horizontalAlignment="stretch"
          >
            <StackLayout spacing="$half" css={{ height: 'auto' }}>
              <HeadingText
                level="2"
                size="md"
                css={{ paddingInlineStart: '$half' }}
              >
                {formatMessage({ id: 'STR_NOTIFICATIONS' })}
              </HeadingText>
              <Switch
                css={{
                  '.cc-switch-root-wrapper': {
                    display: 'flex',
                    alignSelf: 'start',
                  },
                }}
                id="29293"
                label={<TextNotificationsLabel />}
                checked={textNotificationSwitch}
                layout="row"
                onChange={handleToggleTextNotification}
                hideIcon
              />
            </StackLayout>
          </ContentSection>

          <ContentSection
            css={{ height: 'auto', margin: 0 }}
            horizontalAlignment="stretch"
          >
            <StackLayout spacing="$half" css={{ height: 'auto' }}>
              <HeadingText level="2" size="md">
                {formatMessage({ id: 'STR_DOCUMENT_PREFERENCES' })}
              </HeadingText>
              <TextAction
                as={Link}
                to={ROUTE_PATHS.DOCUMENT_PREFERENCES()}
                icon="illustrativeMobileAlertUser"
                indicator="disclosure"
                css={{
                  alignItems: 'start',
                  gap: '$half',
                  '.GDS-icon': {
                    marginBlockStart: '$half',
                    width: '$twoAndHalf',
                  },
                  '@mobile': {
                    gap: '$quarter',
                  },
                }}
              >
                {formatMessage({ id: 'STR_MANAGE_DOCUMENT_PREFERENCES' })}
              </TextAction>
            </StackLayout>
          </ContentSection>
        </Box>
      </Box>
      <TextCommunicationTermsModal
        open={isTextCommunicationTermsModalOpen}
        closeModal={(isTextCommunicationTermsAccepted: boolean) => {
          setIsTextCommunicationTermsModalOpen(false);
          setTextNotificationSwitch(isTextCommunicationTermsAccepted);
        }}
      />
    </Box>
  );
};
