import { Box } from '@leagueplatform/genesis-core';
import type {
  MasonryEngineNode,
  MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { CONTENT_MAX_WIDTH } from 'common/constants/ui';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';

export type PrescriptionDetailsSectionNodeProperties = {
  items: Array<MasonryEngineNode>;
};

export type PrescriptionDetailsSectionNode = MasonryEngineNode<
  'gridLayout',
  PrescriptionDetailsSectionNodeProperties
>;

export type PrescriptionDetailsSectionProps =
  MasonryEngineNodeRendererProps<PrescriptionDetailsSectionNode>;

export const PrescriptionDetailsSection = ({
  items,
}: PrescriptionDetailsSectionProps) => {
  const isBelowTablet = useIsBelowTablet();

  return (
    <Box css={{ paddingInline: '$oneAndHalf' }}>
      <Box
        css={{
          display: 'grid',
          gridTemplateColumns: isBelowTablet ? '1fr' : 'repeat(2, 1fr)',
          gridTemplateRows: isBelowTablet ? '1fr' : 'repeat(4, 1fr)',
          gap: '$one',
          width: '100%',
          maxWidth: `calc(${CONTENT_MAX_WIDTH}px - $two)`,
          gridAutoFlow: isBelowTablet ? 'row' : 'column',
          marginInline: 'auto',
          borderWidth: '$thin',
          borderStyle: isBelowTablet ? 'none' : 'solid',
          borderColor: '$onSurfaceBorderSubdued',
          borderRadius: '$large',
          boxShadow: isBelowTablet ? '$card' : 'none',
          padding: '$two',
          marginBlockStart: '$one',
          backgroundColor: '$surfaceCardSecondary',
        }}
      >
        {items}
      </Box>
    </Box>
  );
};
