import type {
  MasonryEngineNodeRendererProps,
  MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { type FunctionComponent } from 'react';
import { HtmlToReact } from '@leagueplatform/web-common';
import { UtilityText } from '@leagueplatform/genesis-core';

type LearnMoreTextLinkNodeProperties = {
  text: string;
};

type LearnMoreTextLinkNode = MasonryEngineNode<
  'authorizationLearnMoreCard',
  LearnMoreTextLinkNodeProperties
>;

type LearnMoreTextLinkProps =
  MasonryEngineNodeRendererProps<LearnMoreTextLinkNode>;

export const LearnMoreText = ({ text }: LearnMoreTextLinkProps) => (
  <HtmlToReact
    htmlString={text}
    options={{
      span: {
        component: UtilityText as FunctionComponent,
        props: {
          css: {
            paddingBlockStart: '$none',
            paddingBlockEnd: '$quarter',
            typography: '$bodyOne',
          },
        },
      },
    }}
  />
);
