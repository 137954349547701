import { HeadingText, StackLayout } from '@leagueplatform/genesis-core';
import type {
  MasonryEngineNode,
  MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { AuthorizationSectionContainer } from 'components/prior-authorization-status/authorization-section-container.component';
import type { AuthorizationDetailsInfo } from 'common/types/types';

type AuthorizationServiceInfoSectionProperties = {
  heading: string;
  info: AuthorizationDetailsInfo;
};

type AuthorizationServiceInfoSectionNode = MasonryEngineNode<
  'authorizationServiceInfoSection',
  AuthorizationServiceInfoSectionProperties
>;

type AuthorizationDetailsHeaderProps =
  MasonryEngineNodeRendererProps<AuthorizationServiceInfoSectionNode>;

export const AuthorizationServiceInfoSection = ({
  info,
  heading,
}: AuthorizationDetailsHeaderProps) => (
  <StackLayout spacing="$one" horizontalAlignment="stretch">
    <HeadingText level="2" size="lg">
      {heading}
    </HeadingText>
    <AuthorizationSectionContainer info={info} />
  </StackLayout>
);
