import { Suspense } from 'react';
import { GDSThemeProvider, Spinner } from '@leagueplatform/genesis-core';
import { BrowserRouter } from '@leagueplatform/routing';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { useOnlineStatus } from '@leagueplatform/web-common';
import {
  FullPageError,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import League from '@leagueplatform/core';
import { scanHealthTheme } from 'theme/scan-theme';
import { useGetLeagueConfig } from 'hooks/use-get-league-config.hook';
import { ROUTE_PATHS } from 'common/constants/constants';
import { ExitWarningContextProvider } from 'components/modals/exit-warning/exit-warning.context';
import { GenericErrorPage } from 'components/error-page/error-page.component';
import { genericErrorProps } from 'common/constants/error-page';
import 'masonry-registries'; // initialize masonry registries
import AppRoutes from './components/app-routes.component';
import { routesConfig } from './config';

League.overwriteRoutePaths(routesConfig);

export function App() {
  const { isLoading: isConfigLoading, config } = useGetLeagueConfig();
  const { isOffline, isOnline } = useOnlineStatus();

  if (isConfigLoading) {
    return (
      <GDSThemeProvider theme={scanHealthTheme}>
        <Spinner
          loading
          loadingText="Loading..."
          css={{
            display: 'block',
            width: '100vw',
            height: '100vh',
            alignContent: 'center',
            textAlign: 'center',
          }}
        />
      </GDSThemeProvider>
    );
  }

  return (
    <League.LeagueProvider config={config}>
      <ErrorBoundary fallback={<GenericErrorPage {...genericErrorProps} />}>
        <Suspense fallback={<LoadingIndicator />}>
          {isOffline && <FullPageError error={null} route={ROUTE_PATHS.HOME} />}
          {isOnline && (
            <ExitWarningContextProvider>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </ExitWarningContextProvider>
          )}
          <ReactQueryDevtools position="bottom-right" />
        </Suspense>
      </ErrorBoundary>
    </League.LeagueProvider>
  );
}
