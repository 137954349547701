import { useEffect, useState } from 'react';
import type { LeagueConfig } from '@leagueplatform/core';
import { configWithoutAuth, webAuthConfig } from 'config';
import { useMobileAuthListener } from './use-mobile-auth-listener/use-mobile-auth-listener.hook';

type EmbeddedAuthConfig = {
  getUserIdFromMobileHandoff: () => Promise<string>;
  getToken: () => Promise<string>;
};

type UseGetLeagueConfigReturn = {
  isLoading: boolean;
  config?: LeagueConfig | undefined;
};

export const useGetLeagueConfig = (): UseGetLeagueConfigReturn => {
  const { shouldUseMobileAuth, mobileAuthPayload } = useMobileAuthListener();

  const [state, setState] = useState<UseGetLeagueConfigReturn>(() =>
    shouldUseMobileAuth
      ? { isLoading: true }
      : {
          isLoading: false,
          config: {
            ...configWithoutAuth,
            core: {
              ...configWithoutAuth.core,
              auth: webAuthConfig,
            },
          },
        },
  );

  useEffect(() => {
    if (shouldUseMobileAuth && mobileAuthPayload) {
      const mobileAuthConfig: EmbeddedAuthConfig = {
        getToken: () => Promise.resolve(mobileAuthPayload.accessToken),
        getUserIdFromMobileHandoff: () =>
          Promise.resolve(mobileAuthPayload.user['https://el/user_id']),
      };

      setState({
        isLoading: false,
        config: {
          ...configWithoutAuth,
          core: {
            ...configWithoutAuth.core,
            auth: mobileAuthConfig,
          },
        },
      });
    }
  }, [mobileAuthPayload, shouldUseMobileAuth]);

  return state;
};
