import { type PropsWithChildren, useMemo } from 'react';
import { Card, useClickableCard } from '@leagueplatform/genesis-core';

export const useElevatedCard = () => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  const ElevatedCard = useMemo(
    () =>
      ({ children }: PropsWithChildren<{}>) =>
        (
          <Card.Elevated
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: 'transparent',
            }}
          >
            <Card.ClickArea
              ref={clickAreaRef}
              onCardMouseUp={onCardMouseUp}
              onCardMouseDown={onCardMouseDown}
              imagePosition={{ '@initial': 'end', '@mobile': 'top' }}
              css={{
                flexDirection: 'column',
                backgroundColor: '$surfaceCardSecondary',
                paddingInline: '$oneAndHalf',
                paddingBlock: '$oneAndHalf',
                gap: '$quarter',
              }}
            >
              {children}
            </Card.ClickArea>
          </Card.Elevated>
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { primaryActionRef, ElevatedCard };
};
