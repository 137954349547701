import type {
  MasonryEngineNodeRendererProps,
  MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { UtilityText, StackLayout } from '@leagueplatform/genesis-core';
import { HtmlToReact } from '@leagueplatform/web-common';
import { AuthorizationStatusBadge } from 'components/prior-authorization-status/authorization-status-badge.component';
import type { PriorAuthorizationStatuses } from 'common/types/types';

type LearnMoreStatusDefinitionLinkNodeProperties = {
  description: string;
  status: PriorAuthorizationStatuses;
};

type LearnMoreStatusDefinitionLinkNode = MasonryEngineNode<
  'authorizationStatusLearnMoreDefinition',
  LearnMoreStatusDefinitionLinkNodeProperties
>;

type LearnMoreStatusDefinitionLinkProps =
  MasonryEngineNodeRendererProps<LearnMoreStatusDefinitionLinkNode>;

export const LearnMoreDescription = ({
  status,
  description,
}: LearnMoreStatusDefinitionLinkProps) => (
  <StackLayout spacing="$threeQuarters" css={{ marginBlockStart: '$quarter' }}>
    <AuthorizationStatusBadge status={status} />
    <HtmlToReact
      htmlString={description}
      options={{
        span: {
          component: UtilityText as React.FunctionComponent,
          props: {
            css: {
              typography: '$bodyTwo',
              paddingBlockStart: '$none',
              paddingBlockEnd: '$none',
              color: '#2C3135',
            },
          },
        },
      }}
    />
  </StackLayout>
);
