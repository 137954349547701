import { useMutation, useQueryClient } from 'react-query';
import { socketAsFetch } from 'common/utils/socket-as-fetch';
import { GET_USER_PROFILE_MESSAGE_TYPE } from '@leagueplatform/user-profile-api';
import { MUTATION_KEYS, MESSAGE_TYPES } from 'common/constants/api';

export const useSetPostFTUX = (options: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const { onSuccess, onError } = options || {};
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.SET_POST_FTUX],
    mutationFn: async (variables: { userId: string }) => {
      try {
        return await socketAsFetch({
          message_type: MESSAGE_TYPES.SET_POST_FTUX,
          info: {
            onboarding_complete: true,
            user_id: variables.userId,
          },
        });
      } catch (e) {
        if (e instanceof Error) {
          throw new Error(e.message);
        }
        throw new Error("Failed to update user's completed FTUX status");
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([GET_USER_PROFILE_MESSAGE_TYPE]);
      if (onSuccess) onSuccess();
    },
    onError,
  });
};
