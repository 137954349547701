import {
  MasonryDriverNodeRenderersRegistry,
  MasonryDriverActionController,
} from '@leagueplatform/masonry';
import {
  MASONRY_NAMESPACES,
  MASONRY_WIDGET_TYPES,
  MASONRY_ACTION_TYPES,
} from 'common/constants/masonry';
import { RefillScoreCard } from 'components/refill-score/renderers/refill-score-card.renderer';
import { LearnMoreHeader } from 'components/masonry-renderers/learn-more-header.component';
import { RefillScoreLearnMoreDescription } from 'components/refill-score/refill-score-learn-more-definition.component';
import { RefillScoreBadgeImageSummary } from 'components/refill-score/renderers/refill-score-badge-image-summary.renderer';
import { PageHeaderRenderer } from 'components/masonry-renderers/page-header.renderer';

export const initRefillScoreRegistry = () => {
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.REFILL_SCORE,
    MASONRY_WIDGET_TYPES.SIMPLE_BADGE_CARD,
    RefillScoreCard,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.REFILL_SCORE,
    MASONRY_WIDGET_TYPES.LEARN_MORE_HEADER,
    LearnMoreHeader,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.REFILL_SCORE,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.LEARN_MORE.HEADER,
    LearnMoreHeader,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.REFILL_SCORE,
    MASONRY_WIDGET_TYPES.LEARN_MORE_DEFINITION,
    RefillScoreLearnMoreDescription,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.DEFAULT,
    MASONRY_WIDGET_TYPES.LEARN_MORE_DEFINITION,
    RefillScoreLearnMoreDescription,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.REFILL_SCORE,
    MASONRY_WIDGET_TYPES.BADGE_IMAGE_SUMMARY,
    RefillScoreBadgeImageSummary,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.REFILL_SCORE_DETAILS,
    MASONRY_WIDGET_TYPES.EXTRA_SMALL_CARD,
    MasonryDriverNodeRenderersRegistry.getNodeRenderersMap().default
      .extraSmallCard,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.REFILL_SCORE,
    MASONRY_WIDGET_TYPES.SMALL_CARD,
    MasonryDriverNodeRenderersRegistry.getNodeRenderersMap().default.smallCard,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.REFILL_SCORE_DETAILS,
    MASONRY_WIDGET_TYPES.PAGE_HEADER,
    PageHeaderRenderer,
  );

  // ACTIONS
  MasonryDriverActionController.registerHandler(
    MASONRY_ACTION_TYPES.DEEPLINK,
    MASONRY_NAMESPACES.REFILL_SCORE_DETAILS,
    () => {},
  );

  MasonryDriverActionController.registerHandler(
    MASONRY_ACTION_TYPES.DEEPLINK,
    MASONRY_NAMESPACES.REFILL_SCORE,
    () => {},
  );
};
