import { StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import type {
  MasonryEngineNode,
  MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';

export type PrescriptionKeyValueDescriptionNodeProperties = {
  description: string;
  heading: string;
};

export type PrescriptionKeyValueDescriptionNode = MasonryEngineNode<
  'keyValueHeadingDescription',
  PrescriptionKeyValueDescriptionNodeProperties
>;

export type PrescriptionKeyValueDescriptionProps =
  MasonryEngineNodeRendererProps<PrescriptionKeyValueDescriptionNode>;

export const PrescriptionKeyValueDescription = ({
  description,
  heading,
}: PrescriptionKeyValueDescriptionProps) => (
  <StackLayout spacing="$quarter">
    <UtilityText css={{ typography: '$subtitleTwo' }}>{heading}</UtilityText>
    <UtilityText
      css={{
        typography: '$bodyOne',
        color: '$onSurfaceTextSubdued',
      }}
    >
      {description}
    </UtilityText>
  </StackLayout>
);
