import {
  StackLayout,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { Image } from '@leagueplatform/genesis-commons';

import type {
  MasonryEngineNode,
  MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';

type ContactScanDescriptionProperties = {
  body: string;
  heading: string;
  image: string;
};

type ContactScanDescriptionNode = MasonryEngineNode<
  'descriptiveSectionWithImage',
  ContactScanDescriptionProperties
>;

type ContactScanDescriptionRenderer =
  MasonryEngineNodeRendererProps<ContactScanDescriptionNode>;

export const ContactScanDescription = ({
  body,
  heading,
  image,
}: ContactScanDescriptionRenderer) => (
  <StackLayout horizontalAlignment="center" spacing="$one">
    <Image src={image} alt="" height="98" width="98" />
    <HeadingText level="2" size="xl">
      {heading}
    </HeadingText>
    <ParagraphText
      css={{
        textAlign: 'center',
        maxWidth: 588,
      }}
    >
      {body}
    </ParagraphText>
  </StackLayout>
);
