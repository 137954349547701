import type {
  MasonryEngineNodeRendererProps,
  MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { HeadingText } from '@leagueplatform/genesis-core';

type LearnMoreHeaderNodeProperties = {
  heading: string;
};

type LearnMoreHeaderLinkNode = MasonryEngineNode<
  'authorizationLearnMoreHeader',
  LearnMoreHeaderNodeProperties
>;

type LearnMoreHeaderLinkProps =
  MasonryEngineNodeRendererProps<LearnMoreHeaderLinkNode>;

export const LearnMoreHeader = ({ heading }: LearnMoreHeaderLinkProps) => (
  <HeadingText level="2" size="lg">
    {heading}
  </HeadingText>
);
