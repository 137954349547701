import { createPortal } from 'react-dom';
import { type GDSColor, Box } from '@leagueplatform/genesis-core';
import type {
  MasonryEngineNodeRendererProps,
  MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { PageHeader } from 'components/page-header/page-header.component';
import { AuthorizationStatusBadge } from 'components/prior-authorization-status/authorization-status-badge.component';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';
import type { PriorAuthorizationStatuses } from 'common/types/types';
import { PAGE_NAVIGATION_WIDTH, ELEMENT_IDS } from 'common/constants/ui';

type AuthorizationDetailsHeaderProperties = {
  heading: string;
  status: PriorAuthorizationStatuses;
};

type AuthorizationDetailsHeaderNode = MasonryEngineNode<
  'authorizationDetailsHeader',
  AuthorizationDetailsHeaderProperties
>;

type AuthorizationCardProps =
  MasonryEngineNodeRendererProps<AuthorizationDetailsHeaderNode>;

const HeaderBackgroundTokenMap: Record<PriorAuthorizationStatuses, GDSColor> = {
  approved: '$successBackgroundSubdued',
  inProgress: '$warningBackgroundSubdued',
  partiallyApproved: '$highlightBackgroundSubdued',
  denied: '$criticalBackgroundSubdued',
};

export const AuthorizationDetailsHeader = ({
  heading,
  status,
}: AuthorizationCardProps) => {
  const isBelowTablet = useIsBelowTablet();

  const container = document.getElementById(ELEMENT_IDS.PAGE_HEADER);
  const PageHeaderComponent = (
    <PageHeader
      text={heading}
      historyGoBack
      backgroundColor={HeaderBackgroundTokenMap[status]}
      maxWidth={PAGE_NAVIGATION_WIDTH}
      subHeading={
        <Box css={{ position: 'relative' }}>
          <Box
            css={
              isBelowTablet
                ? {
                    position: 'relative',
                    marginBlockStart: '$twoAndHalf',
                  }
                : {
                    position: 'absolute',
                    top: '$half',
                  }
            }
          >
            <AuthorizationStatusBadge status={status} showLight />
          </Box>
        </Box>
      }
    />
  );

  if (container !== null) {
    return createPortal(PageHeaderComponent, container);
  }

  return PageHeaderComponent;
};
