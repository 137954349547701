import {
  MasonryDriverActionController,
  MasonryDriverNodeRenderersRegistry,
} from '@leagueplatform/masonry';
import {
  MASONRY_NAMESPACES,
  MASONRY_WIDGET_TYPES,
  MASONRY_ACTION_TYPES,
} from 'common/constants/masonry';
import { AuthorizationCard } from 'components/prior-authorization-status/renderers/authorization-card.component';
import { AuthorizationDetailsHeader } from 'components/prior-authorization-status/renderers/authorization-details-header.component';
import { AuthorizationServiceCard } from 'components/prior-authorization-status/renderers/authorization-service-card.component';
import { AuthorizationServiceInfoSection } from 'components/prior-authorization-status/renderers/authorization-service-info-section.component';
import { AuthorizationDetailsButton } from 'components/prior-authorization-status/renderers/authorization-details-button.component';
import {
  setPageActionHandler,
  PaginationLayout,
} from 'components/masonry-renderers/pagination-layout.renderer';
import { LearnMoreDescription } from 'components/prior-authorization-status/renderers/learn-more-status-definition.component';
import { LearnMoreText } from 'components/prior-authorization-status/renderers/learn-more-text.component';
import { LearnMoreHeader } from 'components/masonry-renderers/learn-more-header.component';
import { LearnMoreActionLink } from 'components/masonry-renderers/learn-more-action-link.component';
import { LearnMoreAccordion } from 'components/prior-authorization-status/renderers/learn-more-accordion.component';
import { AuthorizationEmptyState } from 'components/prior-authorization-status/renderers/authorization-empty-state.component';

export const initPriorAuthorizationStatusRegistry = () => {
  // STATUS PAGE
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATIONS_STATUS,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.CARD,
    AuthorizationCard,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATIONS_STATUS,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.LEARN_MORE.CARD,
    LearnMoreActionLink,
  );

  // Use default container spinner
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATIONS_STATUS,
    MASONRY_WIDGET_TYPES.CONTAINER_SPINNER,
    MasonryDriverNodeRenderersRegistry.getNodeRenderersMap().default
      .containerSpinner,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.DEFAULT,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.LEARN_MORE.HEADER,
    LearnMoreHeader,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATIONS_STATUS,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.LEARN_MORE.DEFINITION,
    LearnMoreDescription,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.DEFAULT,
    MASONRY_WIDGET_TYPES.RICH_TEXT,
    LearnMoreText,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.DEFAULT,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.LEARN_MORE.STACK,
    LearnMoreAccordion,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATIONS_STATUS,
    MASONRY_WIDGET_TYPES.PAGINATION_LAYOUT,
    PaginationLayout,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATIONS_STATUS,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.EMPTY_STATE,
    AuthorizationEmptyState,
  );

  MasonryDriverActionController.registerHandler(
    MASONRY_ACTION_TYPES.DEEPLINK,
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATIONS_STATUS,
    () => {},
  );

  MasonryDriverActionController.registerHandler(
    'setPage',
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATIONS_STATUS,
    setPageActionHandler,
  );

  // DETAILS PAGE
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATION_STATUS_DETAILS,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.DETAILS_HEADER,
    AuthorizationDetailsHeader,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATION_STATUS_DETAILS,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.SERVICE.CARD,
    AuthorizationServiceCard,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATION_STATUS_DETAILS,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.SERVICE.INFO_SECTION,
    AuthorizationServiceInfoSection,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATION_STATUS_DETAILS,
    MASONRY_WIDGET_TYPES.BUTTON,
    AuthorizationDetailsButton,
  );
};
