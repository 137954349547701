import {
  Button,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { CONTENT_MAX_WIDTH } from '@leagueplatform/web-common-components';
import { SCAN_ONE_PASS } from 'common/constants/constants';
import { ExternalLink } from 'components/external-link/external-link.component';
import { PageHeader } from 'components/page-header/page-header.component';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';

export const ExerciseYourBrain = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'STR_EXERCISE_YOUR_BRAIN' }));
  const isBelowTablet = useIsBelowTablet();

  return (
    <>
      <PageHeader
        historyGoBack
        text={formatMessage({ id: 'STR_EXERCISE_YOUR_BRAIN' })}
      />

      <StackLayout
        orientation={isBelowTablet ? 'vertical' : 'horizontal'}
        spacing={isBelowTablet ? '$two' : '$oneAndHalf'}
        css={{
          maxWidth: CONTENT_MAX_WIDTH,
          marginInline: 'auto',
          paddingBlock: '$two',
          paddingInline: '$oneAndHalf',
        }}
      >
        <ParagraphText css={{ flexShrink: 1 }}>
          {formatMessage({ id: 'STR_EXERCISE_YOUR_BRAIN_DESCRIPTION' })}
        </ParagraphText>

        <Button
          as={ExternalLink}
          indicator="externalLink"
          href={SCAN_ONE_PASS}
          width={isBelowTablet ? 'fillContainer' : 'hugContents'}
          css={{ flexShrink: 0 }}
        >
          {formatMessage({ id: 'STR_GO_TO_ONE_PASS' })}
        </Button>
      </StackLayout>
    </>
  );
};
