import { useState } from 'react';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { useIntl } from '@leagueplatform/locales';
import { LOCAL_STORAGE_KEYS } from 'common/constants/constants';
import { useGetScanUserProfile } from 'hooks/use-get-scan-user-profile/use-get-scan-user-profile.hook';
import { useSetPostFTUX } from 'hooks/use-set-post-ftux.hook';

export const useOnboarding = () => {
  const { formatMessage } = useIntl();
  const [showAccountSetupCompleted, setShowAccountSetupCompleted] =
    useState<boolean>(false);

  const setPostFTUXMutation = useSetPostFTUX({
    onError: () => {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        text: formatMessage({ id: 'STR_ACCOUNT_SETUP_ERROR' }),
      });
    },
    onSuccess: () => {
      setShowAccountSetupCompleted(true);
    },
  });

  const { data: userProfileData, isLoading: userProfileDataIsLoading } =
    useGetScanUserProfile();

  const userCompletedAccountSetup = !!userProfileData?.ftuxComplete;
  const [featureHighlightsCompleted, setFeatureHighlightsCompleted] =
    useState<boolean>(
      !!window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.FEATURE_HIGHLIGHTS_COMPLETED,
      ),
    );

  const onFeatureHighlightsComplete = () => {
    setFeatureHighlightsCompleted(true);
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.FEATURE_HIGHLIGHTS_COMPLETED,
      'true',
    );
  };

  const onCompleteAccountSetup = async () => {
    await setPostFTUXMutation.mutateAsync({
      userId: userProfileData.userId,
    });
  };
  const onCompleteOnboarding = () => setShowAccountSetupCompleted(false);

  return {
    showAccountSetup: featureHighlightsCompleted && !userCompletedAccountSetup,
    showFeatureHighlights:
      !userCompletedAccountSetup && !featureHighlightsCompleted,
    onFeatureHighlightsComplete,
    isLoading: userProfileDataIsLoading,
    showAccountSetupCompleted,
    onCompleteAccountSetup,
    onCompleteOnboarding,
  };
};
