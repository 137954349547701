import { useGetScanUserProfile } from 'hooks/use-get-scan-user-profile/use-get-scan-user-profile.hook';

export const useAccountSetupFormDefaultValues = () => {
  const { data: userProfileData, isLoading } = useGetScanUserProfile();

  const { phoneNumbers, email } = userProfileData || {};
  const { home, mobile } = phoneNumbers || {};
  const homePhoneNumber = home?.number || '';
  const mobilePhoneNumber = mobile?.number || '';

  const isTextNotificationsEnabled =
    !userProfileData?.userProfile?.notificationPreferences?.disableSms;

  return {
    isLoading,
    mobilePhoneNumber,
    homePhoneNumber,
    email: email || '',
    isTextNotificationsEnabled,
  };
};
